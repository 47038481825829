import { useFeatureFlags } from '../../contexts/FeatureFlagContext';
import { Roles } from '../../models/Role';
import { hasPermission } from './usePermissions';
import { useEffect, useState } from 'react';
import { Client } from '../../models/Client';
import User from '../../models/User';
import { DistributionResponse } from '../../models/Distribution';
import { useCurrentUser } from '../../global-state/Auth';
import { useCurrentClient } from '../../global-state/Clients';

type DistributionPermissions = {
  canSendUncontrolledCopy: boolean;
  canCreateDistribution: boolean;
  canMaintainDistribution: boolean;
  canViewDistribution: boolean;
};

export const getDistributionPermissions = (
  distribution: DistributionResponse | undefined | null,
  featureEnabled: boolean,
  currentUser: User,
  currentClient: Client,
): DistributionPermissions => {
  const canViewDistribution = featureEnabled && !!distribution;
  const canCreateDistribution = featureEnabled && !distribution && hasPermission(currentClient.id, currentUser, Roles.TeamMember);
  const canSendUncontrolledCopy = featureEnabled && hasPermission(currentClient.id, currentUser, Roles.TeamMember);
  const canMaintainDistribution = canViewDistribution && hasPermission(currentClient.id, currentUser, Roles.TeamMember);

  return {
    canSendUncontrolledCopy,
    canCreateDistribution,
    canMaintainDistribution,
    canViewDistribution,
  };
};

export const useDistributionPermissions = (distribution: DistributionResponse | undefined | null) => {
  const { featureFlags } = useFeatureFlags();
  const currentUser = useCurrentUser((x) => x.value);
  const currentClient = useCurrentClient((x) => x.value);
  const [userResourcePermissions, setUserResourcePermissions] = useState<DistributionPermissions>({
    canSendUncontrolledCopy: false,
    canMaintainDistribution: false,
    canViewDistribution: false,
    canCreateDistribution: false,
  });

  useEffect(() => {
    if (currentUser && currentClient) {
      setUserResourcePermissions(getDistributionPermissions(distribution, featureFlags.distributionList, currentUser, currentClient));
    }
  }, [distribution, currentClient, currentUser, featureFlags.distributionList]);

  return userResourcePermissions;
};
